import React from "react";
import "./ExternalLinkModal.css";

const ExternalLinkModal = ({show, onClose, onProceed}) => {
    if (!show) {
        return null; // Don't render the modal if it's not shown
    }

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <button className="modal-close" onClick={onClose}>
                    &times;
                </button>
                <p className="modal-text">You will be redirected to an external website.</p>
                <br/>
                <button className="modal-proceed-button" onClick={onProceed}>
                    Proceed
                </button>
            </div>
        </div>
    );
};
export default ExternalLinkModal;
