import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Navbar.css";
import { cdn } from "../../../consts/cdn";

const Logo = cdn.images + "Logo/vNOTES Logo.svg";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let [activeLink, setActiveLink] = useState("");
  let location = useLocation();

  useEffect(() => {
    const displayActiveNav = () => {
      if (location.pathname === "/eu/") {
        setActiveLink("1");
      } else if (
        location.pathname === "/eu/whyvnotes" ||
        location.pathname === "/eu/hcptestimonials" ||
        location.pathname === "/eu/patientstestimonials"
      ) {
        setActiveLink("2");
      } else if (location.pathname === "/eu/clinicalevidence") {
        setActiveLink("3");
      } else if (location.pathname === "/eu/events") {
        setActiveLink("4");
      } else if (location.pathname === "/eu/patientresources") {
        setActiveLink("5");
      } else {
        setActiveLink("none");
      }
    };

    displayActiveNav();
  });

  return (
    <nav className="navbar">
      <div className="navbar__side--left">
        <NavLink to="/eu/">
          <img
            className="navbar__logo"
            src={Logo}
            alt="logo"
            onClick={() => {
              setActiveLink("1");
              setClick(false);
            }}
          />
        </NavLink>

        <input
          type="checkbox"
          className={
            click ? "navigation__checkbox active" : "navigation__checkbox"
          }
          id="navi-toggle"
          onClick={handleClick}
        />
        <label
          htmlFor="navi-toggle"
          className="navigation__button navbar__menu-icon"
        >
          <span className="navigation__icon">&nbsp;</span>
        </label>
      </div>
      <ul
        className={
          click
            ? "navbar__side--right navbar__nav-menu active"
            : "navbar__side--right navbar__nav-menu"
        }
      >
        <li className="navbar__nav-item">
          <NavLink
            exact
            to="/eu/"
            className={
              activeLink === "1" ? "navbar__label active" : "navbar__label"
            }
            onClick={() => {
              setActiveLink("1");
              setClick(false);
            }}
            activeStyle={{
              color: "#365B7E",
              borderBottom: "2px solid #365B7E",
            }}
          >
            Home
          </NavLink>
        </li>
        <hr className="horizontal-line" />
        <li className="navbar__nav-item">
          <NavLink
            exact
            to="/eu/whyvnotes"
            className={
              activeLink === "2" ? "navbar__label active" : "navbar__label"
            }
            onClick={() => {
              setActiveLink("2");
              setClick(false);
            }}
            activeStyle={{
              color: "#365B7E",
              borderBottom: "2px solid #365B7E",
            }}
          >
            Why vNOTES
          </NavLink>
        </li>
        <hr className="horizontal-line" />
        <li className="navbar__nav-item">
          <NavLink
            exact
            to="/eu/clinicalevidence"
            className={
              activeLink === "3" ? "navbar__label active" : "navbar__label"
            }
            onClick={() => {
              setActiveLink("3");
              setClick(false);
            }}
            activeStyle={{
              color: "#365B7E",
              borderBottom: "2px solid #365B7E",
            }}
          >
            Clinical Evidence
          </NavLink>
        </li>
        <hr className="horizontal-line" />
        <li className="navbar__nav-item">
          <NavLink
            exact
            to="/eu/Events"
            className={
              activeLink === "4" ? "navbar__label active" : "navbar__label"
            }
            onClick={() => {
              setActiveLink("4");
              setClick(false);
            }}
            activeStyle={{
              color: "#365B7E",
              borderBottom: "2px solid #365B7E",
            }}
          >
            Events
          </NavLink>
        </li>
        <hr className="horizontal-line" />
        <li className="navbar__nav-item">
          <NavLink
            exact
            to="/eu/patientresources"
            className={
              activeLink === "5" ? "navbar__label active" : "navbar__label"
            }
            onClick={() => {
              setActiveLink("5");
              setClick(false);
            }}
            activeStyle={{
              color: "#365B7E",
              borderBottom: "2px solid #365B7E",
            }}
          >
            Patient Resources
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
