import "./CookieBanner.css";
import { ModalContext } from "../../../../regionLayouts/EULayout";
import { useEffect, useContext } from "react";
import {
  UpdateConsentModeRejectAll,
  UpdateConsentModeAcceptAll,
} from "../../../../utilities/GoogleAnalyticsEvents";

function CookieBanner() {
  const [, , isCookieBannerVisible, setIsCookieBannerVisible] =
    useContext(ModalContext);

  useEffect(() => {
    if (!sessionStorage.getItem("consentDecisionConfirmed")) {
      setIsCookieBannerVisible(true);
    }
  }, [setIsCookieBannerVisible]);

  function handleConsentDecision(decision) {
    if (decision === "accept") {
      UpdateConsentModeAcceptAll();
    } else if (decision === "reject") {
      UpdateConsentModeRejectAll();
    }

    sessionStorage.setItem("consentDecisionConfirmed", true);
    console.log("Consent decision confirmed");
    setIsCookieBannerVisible(false);
  }

  return (
    <div
      className={
        isCookieBannerVisible
          ? "cookie-banner-container active"
          : "cookie-banner-container"
      }
    >
      <div className="disclaimer-container">
        <p className="disclaimer-header">This website uses cookies.</p>

        <p className="disclaimer-description">
          We use cookies and similar technologies in order for the website to
          operate correctly, to understand how visitors engage with us, to
          enhance our content, and to provide a better user experience. By
          clicking on <i>Accept</i> you agree to this, as outlined in our{" "}
          <a
            style={{ color: "#365b7e", textDecoration: "none" }}
            href={"https://appliedmedical.eu/Legal/PrivacyPolicy"}
          >
            <strong>Privacy Policy</strong>
          </a>
        </p>
      </div>
      <div className="consent-options-container">
        <button
          className="consent-banner-button reject-all"
          onClick={() => handleConsentDecision("reject")}
        >
          Continue Without Accepting
        </button>
        <button
          onClick={() => handleConsentDecision("accept")}
          className="consent-banner-button accept-all"
        >
          Accept All
        </button>
      </div>
    </div>
  );
}

export default CookieBanner;
