import { useLocation } from "react-router-dom";
import "../../../App.css";
import "../../_Shared/utility.css";
import "./USModal.css";
import { Button } from "../../Button/Button";

function USModal() {
  let location = useLocation();

  const handleRedirectHome = () => {
    sessionStorage.setItem("popState", "shown");
  };

  const usTimeZones = [
    "America/Adak",
    "America/Anchorage",
    "America/Boise",
    "America/Chicago",
    "America/Denver",
    "America/Detroit",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indianapolis",
    "America/Knox",
    "America/Marengo",
    "America/Petersburg",
    "America/Tell_City",
    "America/Vevay",
    "America/Vincennes",
    "America/Winamac",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Louisville",
    "America/Monticello",
    "America/Los_Angeles",
    "America/Menominee",
    "America/Metlakatla",
    "America/New_York",
    "America/Nome",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Beulah",
    "America/Center",
    "America/New_Salem",
    "America/Phoenix",
    "America/Sitka",
    "America/Yakutat",
    "Pacific/Honolulu",
  ];

  //Pathname to check if user is coming from a direct link from marketing material
  let pathname = location.pathname;

  //'factsheet' & 'cobgynmar24' are approved direct link paths
  let isDirectLink =
    pathname.includes("factsheet") || pathname.includes("cobgynmar24");

  //Log Timezone
  // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

  //Check if user is comming from Ad Campaign
  const isFromAd = () => {
    // Get the current URL
    const queryString = window.location.search;

    // Use URLSearchParams to parse the query string
    const urlParams = new URLSearchParams(queryString);

    // Get the value of the 'fromAd' parameter
    const fromAd = urlParams.get("fromAd");

    // Return true if 'fromAd' is 'true', otherwise false
    return fromAd === "true";
  };

  if (
    (isFromAd() || isDirectLink) &&
    usTimeZones.includes(Intl.DateTimeFormat().resolvedOptions().timeZone)
  ) {
    sessionStorage.setItem("popState", "shown");
  }

  return (
    <div
      className={
        sessionStorage.getItem("popState") !== "shown" &&
        location.pathname !== "/redirect"
          ? "modal__background active"
          : "modal__background"
      }
    >
      <div className="modal__container">
        <div className="modal__header">
          <h1 className="header--primary--blue modal__header--primary">
            Welcome!
          </h1>
          <h2 className="header--secondary--blue modal__header--secondary">
            Please select your region.
          </h2>
        </div>
        <div className="modal__button-container">
          <div
            className="modal__btn-left-container"
            onClick={handleRedirectHome}
          >
            <Button
              className="modal__btn--left"
              route="/"
              aria-label="United States"
            >
              United States
            </Button>

            {/* <Button className="modal__btn--left" route={location.pathname}>
              United States
            </Button> */}
          </div>
          <div className="modal__btn-right-container">
            <Button
              className="modal__btn--right"
              route="/redirect"
              aria-label="Outside of the U.S."
            >
              Outside of the U.S.
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default USModal;
// modal will redirect to current page when United States is selected rather than home with commented out Button component
