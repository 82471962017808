import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./PrivacyPolicy.css";
import {EUBanner} from "../../../Banner/Banner";
import {cdn} from "../../../../consts/cdn";

//images
const BannerDesktop = cdn.images + "PrivacyPolicy/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "PrivacyPolicy/Banner-Mobile.jpg";

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <EUBanner
                imgdesktop={BannerDesktop}
                imgmobile={BannerMobile}
                bold1="PRIVACY POLICY"
                color="#365B7E"
            />
            <div className="content">
                <div className="privacy-policy__disclaimer-container">
                    <p className="paragraph privacy-policy__paragraph">
                        This privacy policy ("Privacy Policy") was last reviewed on August 20, 2024.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        This Privacy Policy is provided to explain the kind of information Applied Medical Resources
                        Corporation (with its affiliated companies, “Applied Medical”) may collect while you visit us
                        online, whether through our websites, including&ensp;
                        <a
                            className="privacy-policy__anchor"
                            href="https://www.appliedmedical.com"
                        >
                            www.appliedmedical.com
                        </a>
                        &nbsp; and other Applied Medical websites, or through other websites or online applications,
                        blogs, social networks, or other online or wireless means via computer, mobile device, or other
                        technology (“Applied Medical Sites” or “Sites”), and how Applied Medical maintains and uses that
                        information. By using the Applied Medical Sites, you are agreeing to the terms of Applied
                        Medical's Privacy Policy. Please be aware that some individual Applied Medical Sites may have
                        their own or additional privacy policies and terms of use, which you should review before using
                        such site, including the Terms of Use available at&ensp;
                        <a
                            className="privacy-policy__anchor"
                            href="https://www.appliedmedical.com/Legal/LegalNotice"
                        >
                            www.appliedmedical.com/Legal/LegalNotice
                        </a>
                        .
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        Some of the information we keep on our server is provided by you voluntarily through forms and
                        other information is collected automatically. Unless you volunteer to submit personal data
                        yourself, Applied Medical will not collect any personal information.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Personal information you choose to provide
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        In General. While Applied Medical does not generally require that you provide us with personally
                        identifiable information in order to visit and use the Applied Medical Sites, in order to
                        purchase products or participate in some other functionality of the Applied Medical Sites (such
                        as filling out a contact form, requesting price lists, sales contract, or catalogs; registering
                        for training; or submitting ideas), we may enable you to provide or post personal information
                        about yourself and/or information that could reasonably be used to contact you or to identify
                        you personally, such as, including but not limited to, first and last name, email address,
                        mailing address, fax number, account number, medical specialty, title, telephone number, and
                        hospital/facility information (collectively, "Personal Information"). If you choose not to
                        provide Personal Information or other information to us, you may not be able to participate in
                        certain functionalities of the Applied Medical Sites. If we combine any demographic or other
                        information we collect about you with Personal Information about you, we will treat the combined
                        information as Personal Information.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        Individuals Under 13 Years of Age. The Applied Medical Sites are open to all individuals. We do
                        not knowingly collect or permit the collection, storage or use of any Personal Information from
                        anyone under 13. If you are a parent or guardian who knows or has discovered that your child
                        under the age of 13 has submitted Personal Information or other information without your
                        permission, upon request, we will promptly: (a) provide direct notice to you indicating what, if
                        any, Personal Information of your child has been collected and how it has been used and/or
                        disclosed; (b) remove your child’s Personal Information or other information from our database,
                        cease the use of such information and direct any other party with access to such information to
                        do the same; and (c) notify you of our compliance with the above.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        Consent to Processing. The Applied Medical Sites are administered from the United States and the
                        Sites may be replicated to various locations around the world to offer better performance to
                        you. If you are located in the European Union or elsewhere outside of the United States, please
                        be aware that information we collect may be transferred to and processed in the United States.
                        By using the Sites, or providing us with any information, you understand and freely give your
                        specific, informed and unambiguous consent to this transfer, processing and storage of your
                        information in the United States, where privacy laws may not necessarily be as comprehensive as
                        those in your country.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Social network applications
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        Functionality on the Applied Medical Sites may permit interactions between the Sites and
                        third-party websites, enabling you to “like,” “share,” or otherwise post content to third-party
                        websites or applications. If you choose to do so, shared information (including any Personal
                        Information) may become public, including being visible on the Sites. The third-party social
                        network websites or applications may allow us to collect and share (or the third party to share)
                        information about you, including Personal Information. We may also receive information about you
                        if other users of social network websites give us access to profiles and you are a “friend” or
                        other connection, or if information about you is otherwise accessible through your social
                        network or interactive webpage or application.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Information automatically collected and stored
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        During your visit, Applied Medical automatically collects and stores the domain and host from
                        which you access the internet, the internet address of the website from which you linked
                        directly to the Applied Medical Sites (if applicable), the date and time you arrived at the
                        Applied Medical Sites, how long you spent on the site, which pages you visited, your internet
                        protocol (IP) address, the location of any device used to access the Applied Medical Sites, and
                        your computer's operating system and browser software.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Cookies and other tracking technologies
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        The technologies used on the Applied Medical Sites to collect Use Information, including Device
                        Identification, may include:
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        "Cookies" (which may be HTML files, Flash files or other technology) are small text files that
                        help store user preferences and activity. A Cookie is placed on a Device when it is used to
                        visit the Sites or otherwise access our online offerings. Cookies may be used for many purposes,
                        such as remembering you and your preferences, tracking your visits to our webpages and
                        customizing your Site experience. If you choose to disable cookies on your Device, some features
                        of the Sites or our online offerings may not function properly.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        “Sessions” are semi-permanent interactive information interchanges between two or more
                        communicating devices. A session is established and later taken down. An established session may
                        involve more than one message in each direction. A session is typically, but not always,
                        stateful, meaning that at least one of the communicating parts needs to save information about
                        the session history in order to be able to communicate. A session is the basic requirement to
                        perform connection-oriented communications. An HTTP exchange between a browser and a remote host
                        may include an HTTP cookie, which identifies state, such as a unique session ID, information
                        about the user's preferences or authorization level.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        “Web beacons” (also known as image tags, pixel tags, GIFS or web bugs) are small pieces of code
                        used to collect advertising data, such as counting page views, promotion views or advertising
                        responses. Web beacons or similar technologies may be used for a number of purposes, including,
                        without limitation, to count visitors to the Applied Medical Sites, to monitor how users
                        navigate the Sites, to count how many sent emails were actually opened or to determine whether
                        content sent was actually viewed.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        An embedded script is programming code that is designed to collect information about your
                        interactions with the Applied Medical Sites, such as the links you click on. The code is
                        temporarily downloaded onto your Device from our server or a third-party service provider, is
                        active only while you are connected to the Sites, and is deactivated or deleted thereafter.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        This website processes the following cookies:
                    </p>
                    <br/>
                    <div className="privacy-policy__table-container">
                        <table className="privacy-policy__table">
                            <tbody>
                            <tr className="privacy-policy__table-row">
                                <th>Name</th>
                                <th>Type</th>
                                <th>(Third-Party) Recipient</th>
                                <th>Purpose</th>
                                <th>Domain</th>
                                <th>Expires</th>
                                <th>Opt Out</th>
                            </tr>

                            <tr className="privacy-policy__table-row">
                                <td>Locale</td>
                                <td>strictly necessary, preferences</td>
                                <td>Applied Medical</td>
                                <td>Used to determine preferred localized version of the Applied Medical website</td>
                                <td>www.appliedmedical.com</td>
                                <td>Session</td>
                                <td>Not Possible</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>ASP.NET_SessionId</td>
                                <td>strictly necessary</td>
                                <td>Applied Medical</td>
                                <td>Used to distinguish active visitor sessions</td>
                                <td>www.appliedmedical.com</td>
                                <td>Session</td>
                                <td>Not Possible</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>ArraAffinitySameSite</td>
                                <td>strictly necessary, preferences</td>
                                <td>Microsoft Azure</td>
                                <td>Used for networking (loadbalancer) purposes</td>
                                <td>www.appliedmedical.com</td>
                                <td>Session</td>
                                <td>Not Possible</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>ArraAffinity</td>
                                <td>strictly necessary, preferences</td>
                                <td>Microsoft Azure</td>
                                <td>Used for networking (loadbalancer) purposes</td>
                                <td>www.appliedmedical.com</td>
                                <td>Session</td>
                                <td>Not Possible</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>_hjid</td>
                                <td>statistics, marketing</td>
                                <td>Hotjar</td>
                                <td>Used to distinguish users visiting the Applied Medical website</td>
                                <td>www.appliedmedical.com</td>
                                <td>365 days</td>
                                <td>Possible</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>_ga</td>
                                <td>statistics, marketing</td>
                                <td>Google Analytics</td>
                                <td>Used to distinguish users visiting the Applied Medical website</td>
                                <td>www.appliedmedical.com</td>
                                <td>730 days</td>
                                <td>Possible</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">Security</h1>
                    <p className="paragraph privacy-policy__paragraph">
                        Taking into account the state of the art, the costs of implementation and the nature, scope,
                        context and purposes of processing as well as the risk of varying likelihood and severity for
                        the rights and freedoms of natural persons, Applied Medical shall implement appropriate
                        technical and organizational measures to ensure a level of security appropriate to the risk.
                        In assessing the appropriate level of security, account shall be taken of the risks that are
                        presented by processing, in particular from accidental or unlawful destruction, loss,
                        alteration, unauthorized disclosure of, or access to Personal Data transmitted, stored or
                        otherwise processed.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Use of the information you provide
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        Applied Medical will use your personal information for the purpose for which you provided it and
                        related administrative purposes. For example, our use of such personal information
                        may include responding to your requests, developing records of you, your purchase or interest in
                        our products, contacting you with information that might be of interest to you
                        (including information about products of ours and others), improving your experience on the
                        Applied Medical Sites, providing you with support and answering questions or other
                        requests, enforcing this Privacy Policy and the Terms of Use of the Applied Medical Sites,
                        protecting our rights and property, and complying with a law or regulation, including
                        responding to government investigations, validly issued subpoenas, and court orders.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        Your submission of ideas, patient experiences, orders for product, and registration for training
                        are governed by this Privacy Policy, the Terms of Use, and (as applicable) by other
                        agreements, policies, and terms and conditions. ur use of the information you provide for these
                        purposes is subject to those agreements, policies, and terms and conditions.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        In the ordinary course of business, we may share some personal information with third-party
                        companies that we hire to perform services or functions on our behalf and consistent
                        with this Privacy Policy. Such services might include providing content, fulfilling your
                        requests, improving our products, content, services or advertising, or otherwise performing
                        services for Applied Medical. Additionally, we may share your information in order to host or
                        operate the Sites, for data analysis and for administrative purposes. In such cases, we
                        share the minimum amount of information reasonably necessary for the third party to complete its
                        function. In all cases, we will not authorize them to keep, disclose, or use your
                        information except for the purpose of providing the services for which they have been
                        contracted.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        Applied Medical will not otherwise sell, rent, publish or exchange your personally identifiable
                        information to any other organization or entity unless you are first notified and expressly
                        agree to it, or as otherwise required by law.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        Applied Medical or any of its assets, including the Applied Medical Sites, may be sold, or other
                        transactions may occur in which your Personal Information is one of the assets in the
                        transaction. As a result Applied Medical reserves the right to disclose and transfer user
                        information, including Personal Information, in connection with any significant corporate event,
                        such as a merger, sale, dissolution, or acquisition, in which such information is one of the
                        business assets transferred.
                    </p>
                    <br/>
                    <p className="paragraph privacy-policy__paragraph">
                        We may transfer and disclose information, including your Personal Information, Use Information,
                        and Device Identification (including IP address), to third parties: (i) when we believe
                        that the law requires it; (ii) at the request of governmental authorities conducting an
                        investigation; (iii) to enforce the Terms of Use or other applicable policies; (iv) to respond
                        to an
                        emergency; (v) to protect the rights, property, or safety of the visitors to the Sites or
                        others; or
                        (vi) to identify users in cooperation with copyright owners, internet service providers,
                        wireless service providers or law enforcement agencies. These transfers and disclosures may be
                        done with or without notice to you.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Opting out of email
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        If you provide us with your email address, we may send you emails
                        regarding Applied Medical or the products we offer. You can choose
                        to opt out of receiving email from Applied Medical at any time. If
                        you wish to opt out with respect to more than one email address, you
                        must provide a separate request for each email address. Every email
                        sent from Applied Medical will include appropriate unsubscribe
                        instructions. To opt out of all email marketing messages from us,
                        you must follow the instructions provided with each email.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Links to other sites
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        The Applied Medical Sites may contain links to other websites. Site content or widgets may be
                        included on websites of third parties. These other websites may send their own cookies
                        to you, and they may independently collect data or Personal Information. A link or other
                        connection from the Applied Medical Sites to any other website does not indicate that Applied
                        Medical endorses or recommends that website. Applied Medical provides such links and connections
                        for your convenience. These other sites are neither controlled by Applied
                        Medical nor subject to the terms of this Privacy Policy. Applied Medical is not responsible for
                        any of the content of, or information collected by, those sites. We encourage you to read
                        the privacy statements of all such sites you may visit.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Notice to EEA Residents
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        This Privacy Notice for EEA Residents supplements the information contained in Applied Medical’s
                        Privacy Policy and applies if you are located in the EEA. Applied Medical adopts this
                        notice to comply with the General Data Protection Regulation (2016). Any terms defined in the
                        GDPR have the same meaning when used in this notice.
                    </p>
                    <p className="paragraph privacy-policy__paragraph">
                        Applied Medical collects information that identifies, relates to, describes, references, is
                        capable of being associated with, or can reasonably be linked, directly or indirectly, with you.
                        In
                        particular, Applied Medical may have collected the following categories of personal information
                        from you within the last twelve (12) months:
                    </p>
                    <br/>
                    <div className="privacy-policy__table-container">
                        <table className="privacy-policy__table">
                            <tbody>
                            <tr className="privacy-policy__table-row">
                                <th>Category</th>
                                <th>Examples</th>
                                <th>Purpose</th>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>Identifiers</td>
                                <td>Real name, email address, postal address, phone number, fax number, IP address</td>
                                <td></td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>Professional or employment
                                    related information
                                </td>
                                <td>Medical specialty, job title, hospital/facility information, medical license number,
                                    NPI number
                                </td>
                                <td>See: Use of the information you provided
                                </td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>Internet or other
                                    electronic activity
                                </td>
                                <td>Domain and hosting information, internet address of the website from which you
                                    linked directly to the
                                    Applied Medical Sites, the date and time you arrived at the Applied Medical Sites,
                                    how long you spent on
                                    the site, which pages you visited, your computer’s operating system and browser
                                    software
                                </td>
                                <td>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Data Subject Rights
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        Subject to a verifiable request and certain exceptions as defined by the GDPR, as a Data Subject
                        you have the following rights:
                    </p>
                    <ul className="paragraph privacy-policy__paragraph">
                        <li className="privacy-policy--li">
                            To access to your Personal Data that we process on you, including information on the
                            categories of data we collect, the purpose for which Applied Medical collected the
                            personal
                            information, the categories of third parties to whom Applied Medical disclosed your
                            personal
                            information, the retention period and the legal basis for the processing of your data.
                        </li>
                        <li className="privacy-policy--li">
                            To rectify inaccurate or incomplete Personal Data.
                        </li>
                        <li className="privacy-policy--li">
                            To erase your Personal Data.
                        </li>
                        <li className="privacy-policy--li">
                            To restrict the processing of your Personal Data.
                        </li>
                        <li className="privacy-policy--li">
                            To move, copy or transfer your Personal Data (data portability). This right only applies
                            when you have directly provided the Personal Data and where the processing relies on
                            consent or where it is necessary for entering into, or performance of, a contract
                            between us.
                        </li>
                        <li className="privacy-policy--li">
                            To object to the processing based on legitimate interests (including profiling); direct
                            marketing (including profiling) and processing for purposes of scientific/historical
                            research and
                            statistics.
                        </li>
                        <li className="privacy-policy--li">
                            Not to be subjected to a decision based solely on automated processing, including
                            profiling: (e.g., automated processing of Personal Data to evaluate certain aspects
                            about you),
                            which produces legal effects concerning you or similarly significantly affects you,
                            unless it is necessary for entering into, or performance of, a contract between us; or
                            authorized by
                            law (e.g., for the purposes of fraud or tax evasion); or you have provided your explicit
                            consent.
                        </li>
                        <li className="privacy-policy--li">
                            To revoke your consent for further processing of your Personal Data when such processing
                            is based on your consent.
                        </li>
                    </ul>
                    <p className="paragraph privacy-policy__paragraph">
                        To exercise any of these rights, please send a message to{" "}
                        <a className="terms__anchor"
                           href="mailto:DPO-Europe@appliedmedical.com">
                            DPO-Europe@appliedmedical.com
                        </a> or write
                        us at Applied Medical Europe B.V., Wiekenweg 21, 3815 KL Amersfoort (att. DPO). We will provide
                        you with information on the action taken within one month after the successful submission of
                        your request through this email. When processing your request, we are required to verify your
                        identity including, but not limited to, the verification of your email address. If you do not
                        allow us to verify your identity, we cannot proceed to process your request.
                    </p>
                    <p className="paragraph privacy-policy__paragraph">
                        Please note that the abovementioned rights, with the exception of the right to object to direct
                        marketing, are not absolute. Under certain conditions and in line with applicable data
                        protection legislation, we may refuse a request. When such a restriction (partially) applies to
                        your
                        request, we will inform you on the reason of our refusal to comply with your
                        request.
                    </p>
                    <br/>
                </div>
                <div className="privacy-policy__disclaimer-container">
                    <h1 className="header--primary privacy-policy__header">
                        Data Transfers
                    </h1>
                    <p className="paragraph privacy-policy__paragraph">
                        We use third-party vendors for business, professional and technical support as Processors. These
                        companies only use your Personal Data on behalf of us and under our written
                        instructions. Specifically, we use such third-party vendors and their servers and services for
                        our web platforms, storage and business-related data processing. In limited circumstances
                        these vendors might have access to your Personal Data when this is required for maintenance or
                        problem solving. For more information, please read our Third-Party Vendors List.
                    </p>
                    <br/>
                    <div className="privacy-policy__table-container">
                        <table className="privacy-policy__table">
                            <tbody>
                            <tr className="privacy-policy__table-row">
                                <th>System</th>
                                <th>External Supplier</th>
                                <th>Location</th>
                                <th>Cross Border Transaction Measure</th>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>Webhosting</td>
                                <td>TransIP</td>
                                <td>Germany</td>
                                <td>(within EEA)</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>Webhosting</td>
                                <td>Microsoft</td>
                                <td>US, EU</td>
                                <td>(within EEA/SCC)</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>Analytics</td>
                                <td>Google</td>
                                <td>US, EU</td>
                                <td>(within EEA/SCC)</td>
                            </tr>
                            <tr className="privacy-policy__table-row">
                                <td>Analytics</td>
                                <td>Hotjar</td>
                                <td>Ireland</td>
                                <td>(Within EEA)</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <div className="paragraph privacy-policy__paragraph">
                            <p className="paragraph privacy-policy__paragraph">
                                We have data processing agreements in place with such vendors where required by law.
                            </p>
                        </div>
                        <div className="paragraph privacy-policy__paragraph">
                            <p className="paragraph privacy-policy__paragraph">
                                Some of your Personal Data that we collect may be transferred and stored in countries
                                outside of the EEA. The laws in those countries may not provide the same level of data
                                protection compared to the country in which you initially provided your data. When we
                                transfer your Personal Data to recipients in other countries, we will protect that
                                Personal Data as described in this Statement and in compliance with applicable laws.
                            </p>
                        </div>
                        <div className="paragraph privacy-policy__paragraph">
                            <p className="paragraph privacy-policy__paragraph">
                                We use various measures to ensure that your Personal Data transferred outside of the EEA
                                receives adequate protection in accordance with data protection legislation, as the
                                conclusion of a data transfer agreement including the EU Standard Contractual Clauses.
                            </p>
                        </div>
                        <div className="paragraph privacy-policy__paragraph">
                            <p className="paragraph privacy-policy__paragraph">
                                Applied Medical Europe B.V. and its affiliates in Europe have concluded a data transfer
                                agreement (Controller to Controller) in turn with Applied Medical Resources Corporation.
                                according to the Model of the European Commission. Applied Medical Resources Corporation
                                has in its turn concluded a data transfer agreement (Controller to Processor) with its
                                third-party vendors according to the Model Standard Contractual Clauses of the European
                                Commission published 4 June 2021. The nature of the data and the data transfer present a
                                relatively low risk. Appropriate safeguards have been implemented. Depending on the
                                circumstances of this data transfer, the organization considers that the personal data
                                is adequately protected by the SCCs and the additional safeguards.
                            </p>
                        </div>
                        <br/>
                        <div className="privacy-policy__disclaimer-container">
                            <h1 className="header--primary privacy-policy__header">
                                Changes to this privacy policy
                            </h1>
                            <p className="paragraph privacy-policy__paragraph">
                                Applied Medical may at any time update this Privacy Policy. We will provide notice about
                                significant changes by either sending a notice to the primary email address we have on
                                file for you or by placing a prominent notice on the Privacy Policy webpage. Any changes
                                become effective immediately upon posting. Your continued use of the Applied Medical
                                Sites after any changes to the Privacy Policy are made will constitute your agreement to
                                be bound by those changes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
