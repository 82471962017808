import React from "react";
import "./ClinicalStudiesTile.css";
import {ChevronDown} from "react-bootstrap-icons";

export const ClinicalStudiesTile = ({
                                        header,
                                        date,
                                        studyurl,
                                        boldtext,
                                        doctors,
                                        content,
                                        uniqueid,
                                        onExternalLinkClick, // New prop for handling external link click
                                    }) => {
    const handleClick = (e) => {
        if (onExternalLinkClick) {
            e.preventDefault(); // Prevent default navigation
            onExternalLinkClick(studyurl); // Call the handler with the URL
        }
    };

    return (
        <div className="clinicalstudiestile__tile">
            <div className="clinicalstudiestile__tile-left">
                <h3 className="clinicalstudiestile__tile-left-title">{header}</h3>
                <br/>
                <span className="clinicalstudiestile__date-mobile clinicalstudiestile__tile-date">
          {date}
        </span>
                <br/>
                <a
                    href={studyurl}
                    className="clinicalstudiestile__link"
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleClick} // Attach the click handler
                >
                    <button className="clinicalstudiestile__tile-button clinicalstudiestile__desktop-only btn">
                        Read Study
                    </button>
                </a>
            </div>
            <div className="clinicalstudiestile__tile-right">
                <h3 className="clinicalstudiestile__tile-right-title">{boldtext}</h3>
                <br/>
                <span className="clinicalstudiestile__tile-doctors">{doctors}</span>
                <br/>
                <p className="clinicalstudiestile__tile-content">{content}</p>
                <div className="clinicalstudiestile__mobile-only">
                    <br/>
                    <hr/>
                    <br/>
                    <div className="clinicalstudiestile__mobile-content">
                        <input
                            type="checkbox"
                            id={uniqueid}
                            name="checkbox"
                            className="summary__checkbox"
                        />
                        <label className="summary__label" htmlFor={uniqueid}>
              <span className="clinicalstudiestile__mobile-content-summary">
                Summary
              </span>
                            <ChevronDown className="summary-expansion-arrow"/>
                        </label>
                        <p className="summary__dropdown">{content}</p>
                    </div>
                    <br/>
                    <a
                        href={studyurl}
                        className="clinicalstudiestile__link"
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleClick} // Attach the click handler
                    >
                        <button className="clinicalstudiestile__tile-button btn">
                            Read Study
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ClinicalStudiesTile;
