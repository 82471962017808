import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./PatientResources.css";
import { EUBanner } from "../../../Banner/Banner.js";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../consts/cdn.js";
import { slide1, slide2 } from "../../../Carousel/CarouselData.js";
import Carousel from "../../../Carousel/Carousel.js";

//Images
const BannerDesktop = cdn.images + "Patients/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Patients/Banner-Mobile.jpg";
const Image1a = cdn.images + "Patients/Image1a.jpg";
const Image1b = cdn.images + "Patients/Image1b.jpg";
const Image1c = cdn.images + "Patients/Image1c.jpg";
const Image1d = cdn.images + "Patients/Image1d.jpg";
const Image5d = cdn.images + "Patients/Image5d.svg";
const CircleBackgroundDesktop =
  cdn.images + "Patients/CircleBackground--Desktop.png";
const CircleBackgroundMobile =
  cdn.images + "Patients/CircleBackground--Mobile.png";

function PatientResources() {
  return (
    <div className="patients">
      <Helmet>
        <title>vNOTES Hysterectomy</title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) is another minimally invasive approach to consider for your hysterectomy and other gynecologic…"
        />
      </Helmet>
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        boldTitle1="A DIFFERENT"
        boldTitle2="OPTION FOR YOUR"
        boldTitle3="HYSTERECTOMY"
        subtitle="Find out if vNOTES is the right hysterectomy option for you."
        color="#EA6100"
      />
      <div className="content">
        <div className="hcphome__extrapadding">
          <h1 className="header-primary header-primary--orange header-primary--comfortaa">
            What is{" "}
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES?
          </h1>
          <p className="paragraph">
            If your doctor has recommended a hysterectomy to treat a condition
            &ndash; such as pelvic pain, heavy periods or a prolapsed uterus
            &ndash;{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            (vaginal natural orifice transluminal endoscopic surgery) may be an
            option for you. In a{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            procedure, surgeons use specialized instruments inserted through the
            vagina instead of making incisions in the abdomen.
          </p>
        </div>
        <div className="patients__img-container patients__img-container--1">
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1a}
              alt="Open Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Open Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1b}
              alt="Laparoscopic Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Laparoscopic Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1d}
              alt="Laparoscopic Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Robotic Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1c}
              alt="vNOTES"
              loading="lazy"
            />
            <div className="patients__img-caption" style={{ color: "#4B759D" }}>
              vNOTES
            </div>
          </div>
        </div>

        <br className="br__desktop-only" />

        <div className="hcphome__extrapadding">
          <p className="paragraph">
            In addition to hysterectomies,{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            can be used for other gynecologic procedures:
          </p>

          <ul className="patients__list">
            <li>
              <p className="paragraph">Fallopian tube or ovarian procedure</p>
            </li>
            <li>
              <p className="paragraph">Permanent sterilization</p>
            </li>
            <li>
              <p className="paragraph">Pelvic organ prolapse repair</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="patients__section" style={{ background: "#fafafa" }}>
        <div className="patients__half-text hcphome__extrapadding">
          <h1
            className="header-primary--orange header-primary--comfortaa"
            style={{ margin: "0px" }}
          >
            vNOTES PROVIDES THE
            <br />
            FOLLOWING BENEFITS
            <br />
            COMPARED TO
            <br />
            LAPAROSCOPIC SURGERY
            <sup
              className="ref__style patients__header-ref"
              data-background-color="#FAFAFA"
              data-text-color="#707070"
              data-tip="Baekelandt JF, De Mulder PA, Le Roy I, et al. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial. BJOG. 2019;126(1):105-113. doi:10.1111/1471-0528.15504"
              data-class="sup__style"
              data-arrow-color="transparent"
              data-html="true"
            >
              REF
            </sup>
            :
          </h1>
        </div>
        <div className="patients__half-img hcphome__extrapadding">
          <Carousel data={slide1} carousel_name="benefits-carousel" autoplay />
        </div>
      </div>

      <div className="content content--2 hcphome__extrapadding">
        <div className="patients__flex">
          <div className="patients__half-img patients__half-img--2">
            <img src={Image5d} alt="message icon" />
          </div>

          <div className="patients__half-text patients__text-right">
            <h1
              className="header-primary--orange header-primary--comfortaa"
              style={{ margin: "0px" }}
            >
              START THE <br className="br__mobile-only--patients" />
              CONVERSATION.
            </h1>
            <br />

            <p className="paragraph paragraph--width">
              This content is provided for educational and informational
              purposes only. Like all surgeries, vNOTES has risks, including the
              possibility of conversion to traditional methods. Consult your
              healthcare provider to determine whether a vNOTES procedure is an
              option for you.
            </p>
          </div>
        </div>
      </div>

      <div className="hcphome__gray-container">
        <div
          className="content hcphome__right-content"
          style={{ position: "absolute", textAlign: "left" }}
        >
          <h1
            className="header-primary header-primary--orange header-primary--comfortaa"
            style={{ margin: "0px" }}
          >
            LEARN WHAT TO <br />
            EXPECT WITH <br className="br__mobile-only" />
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES.{" "}
          </h1>
        </div>
        <img
          className="banner__img banner__img--desktop patients__banner--desktop"
          src={CircleBackgroundDesktop}
          alt=""
        />
        <img
          className="banner__img banner__img--mobile"
          src={CircleBackgroundMobile}
          alt=""
        />
      </div>

      <div className="content content--carousel">
        <Carousel
          data={slide2}
          carousel_name="procedure-carousel"
          singleImage
        />
      </div>

      <ReactTooltip />
    </div>
  );
}

export default PatientResources;
