import React, { createContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { SetDefaultConsentMode } from "../utilities/GoogleAnalyticsEvents";
import Navbar from "../../src/components/Navbar/EUNavbar/Navbar";
import FindSurgeon from "../../src/components/Pages/EUPages/Patients/FindSurgeon/FindSurgeon";
import ContactUs from "../../src/components/Pages/EUPages/ContactUs/ContactUs";
import PrivacyPolicy from "../../src/components/Pages/EUPages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../../src/components/Pages/EUPages/Terms/Terms";
import Home from "../../src/components/Pages/EUPages/Home/Home";
import ClinicalStudies from "../../src/components/Pages/EUPages/HealthcareProviders/ClinicalStudies/ClinicalStudies";
import Events from "../../src/components/Pages/EUPages/HealthcareProviders/Events/Events";
import WhyVnotes from "../components/Pages/EUPages/WhyVnotes/WhyVnotes";
import PatientResources from "../components/Pages/EUPages/Patients/PatientResources";
import PatientTestimonials from "../../src/components/Pages/EUPages/Testimonials/Patients/Patient-testimonials";
import HCPTestimonials from "../../src/components/Pages/EUPages/Testimonials/HCP/HCP-testimonials";
import RedirectPage from "../../src/components/Pages/EUPages/Redirect/Redirect";
import NotFound from "../../src/components/Pages/EUPages/NotFound/NotFound";
import EUFooter from "../components/Footer/EUFooter/EUFooter";
import { generateHreflangTags } from "../utilities/generateHreflangTags";
import { ScrollDepthTracker } from "../utilities/ScrollDepthTracker";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import SEO from "../components/SEO";
import HCPModal from "../components/Modals/HCP/HCPModal/HCPModal";
import CookieBanner from "../components/Modals/DataConsent/CookieBanner/CookieBanner";
import { InitializeGA } from "../utilities/GoogleAnalyticsEvents";

export const ModalContext = createContext();

const EULayout = ({ region }) => {
  const [isHCPModalVisible, setIsHCPModalVisible] = useState(false);
  const [forwardPath, setForwardPath] = useState();
  // const [isDataConsentModalVisible, setIsDataConsentModalVisible] =
  //   useState(false);
  // const [isManagePreferencesModalVisible, setIsManagePreferencesModalVisible] =
  //   useState(false);
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (region === "EU" && !location.pathname.startsWith("/eu")) {
      history.push("/eu/");
    }
  }, [location, region, history]);

  useEffect(() => {
    SetDefaultConsentMode();
    InitializeGA(region);
  }, [region]);

  const seoData = {
    "/eu/": {
      title: "Home - vNOTES EU",
      description:
        "Welcome to vNOTES - the next generation in minimally invasive gynecologic surgery.",
      keywords: "vnotes, minimally invasive surgery, gynecologic surgery",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/`,
    },
    "/eu/clinicalevidence": {
      title: "Clinical Evidence - vNOTES EU",
      description:
        "Explore clinical studies on vNOTES surgery to understand its efficacy and safety.",
      keywords:
        "vnotes clinical evidence, vnotes studies, gynecologic surgery research",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/clinicalevidence`,
    },
    "/eu/events": {
      title: "Events - vNOTES EU",
      description:
        "Join us at upcoming events to learn more about vNOTES surgical techniques and advancements.",
      keywords:
        "vnotes events, surgical conferences, gynecologic surgery events",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/events`,
    },
    "/eu/healthcareprofessionals": {
      title: "Healthcare Professionals - vNOTES EU",
      description:
        "Resources and training for healthcare professionals specializing in vNOTES.",
      keywords:
        "vnotes healthcare professionals, medical training, gynecologic surgery resources",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/healthcareprofessionals`,
    },
    "/eu/patients": {
      title: "For Patients - vNOTES EU",
      description:
        "Learn more about how vNOTES can provide a minimally invasive option for gynecologic surgery.",
      keywords: "vnotes for patients, minimally invasive gynecologic surgery",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/patients`,
    },
    "/eu/patientstestimonials": {
      title: "Patient Testimonials - vNOTES EU",
      description:
        "Hear from patients who have experienced the benefits of vNOTES surgery.",
      keywords: "vnotes testimonials, patient experiences, gynecologic surgery",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/patientstestimonials`,
    },
    "/eu/hcptestimonials": {
      title: "HCP Testimonials - vNOTES EU",
      description:
        "Testimonials from healthcare professionals on the efficacy of vNOTES.",
      keywords:
        "vnotes hcp testimonials, professional reviews, gynecologic surgery",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/hcptestimonials`,
    },
    "/eu/findasurgeon": {
      title: "Find a Surgeon - vNOTES EU",
      description:
        "Locate a certified surgeon trained in the vNOTES technique near you.",
      keywords: "find a vnotes surgeon, gynecologic surgery",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/findasurgeon`,
    },
    "/eu/contactus": {
      title: "Contact Us - vNOTES EU",
      description:
        "Get in touch with the vNOTES team for more information and support.",
      keywords: "vnotes contact, gynecologic surgery contact",
      image:
        "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
      url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net/eu/contactus`,
    },
  };

  // Retrieve metadata based on the current path
  const currentSEO = seoData[location.pathname] || {
    title: "vNOTES EU – Advanced Minimally Invasive Gynecologic Surgery",
    description:
      "vNOTES (vaginal natural orifice transluminal endoscopic surgery) modernizes vaginal surgery.",
    keywords: "vnotes, surgery, gynecology",
    image:
      "https://amccorpwebappsstorageprd.blob.core.windows.net/appliedmedicalus/IMG/vNOTES_OG.png",
    url: `https://corpwebappstaging-tamisrewrite.azurewebsites.net${location.pathname}`,
  };

  // Generate hreflang tags for the current route and region
  const hreflangTags = generateHreflangTags(location.pathname, region) || [];

  return (
    <ModalContext.Provider
      value={[
        // isDataConsentModalVisible,
        // setIsDataConsentModalVisible,
        // isManagePreferencesModalVisible,
        // setIsManagePreferencesModalVisible,
        isHCPModalVisible,
        setIsHCPModalVisible,
        isCookieBannerVisible,
        setIsCookieBannerVisible,
        forwardPath,
        setForwardPath,
      ]}
    >
      {/* style={{ pointerEvents: "none" }} */}
      <div>
        <SEO
          title={currentSEO.title}
          description={currentSEO.description}
          keywords={currentSEO.keywords}
          image={currentSEO.image}
          url={currentSEO.url}
        />
        {hreflangTags.map((tag) => (
          <link
            key={tag.lang}
            rel="alternate"
            href={tag.url}
            hrefLang={tag.lang}
          />
        ))}

        <Navbar />
        <ScrollDepthTracker />
        <HCPModal />
        <ScrollToTop />

        <Switch>
          <Route path="/eu/" exact component={Home} />
          <Route
            path="/eu/clinicalevidence"
            exact
            component={ClinicalStudies}
          />
          <Route path="/eu/clinicalevidence/:ref" component={ClinicalStudies} />
          <Route path="/eu/events" exact component={Events} />
          <Route path="/eu/WhyVnotes" exact component={WhyVnotes} />
          <Route path="/eu/WhyVnotes/:ref" component={WhyVnotes} />
          <Route
            path="/eu/patientresources"
            exact
            component={PatientResources}
          />
          <Route
            path="/eu/patientstestimonials"
            exact
            component={PatientTestimonials}
          />
          <Route path="/eu/hcptestimonials" exact component={HCPTestimonials} />
          <Route path="/eu/findasurgeon" exact component={FindSurgeon} />
          <Route path="/eu/contactus" exact component={ContactUs} />
          <Route path="/eu/redirect" exact component={RedirectPage} />
          <Route path="/eu/privacypolicy" exact component={PrivacyPolicy} />
          <Route path="/eu/legalnotice" exact component={Terms} />
          <Route path="*" component={NotFound} />
        </Switch>

        <EUFooter />
        <CookieBanner />
      </div>
    </ModalContext.Provider>
  );
};

export default EULayout;
